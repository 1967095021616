

const Fjernstyring = () => {
    
    
    
    return (
        <div className="frontpage-wrapper">
            Fjernstyring
        </div>
    )
}

export default Fjernstyring