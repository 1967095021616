import { Routes, Route } from "react-router-dom"

import Header from './modules/Header'
import Footer from './modules/Footer'

import Frontpage from './pages/Frontpage'
import Tjenester from './pages/Tjenester'
import Kontakt from './pages/Kontakt'
import OmOss from './pages/OmOss'
import Fjernstyring from './pages/Fjernstyring'
import Personvern from './pages/Personvern'

const App = () => {
    return (
        <>
            <Header />
            <Routes>
                <Route path="/" element={<Frontpage />} />
                <Route path="/tjenester" element={<Tjenester />} />
                <Route path="/kontakt" element={<Kontakt />} />
                <Route path="/om-oss" element={<OmOss />} />
                <Route path="/fjernstyring" element={<Fjernstyring />} />
                <Route path="/personvern" element={<Personvern />} />
            </Routes>
            <Footer />
        </>
    )
}

export default App