import { NavLink } from "react-router-dom"

const Footer = () => {
    
    function scrollToTop() {
        window.scrollTo(0, 0)
    }

    return (
        <div className="footer">
            <div className="footer-inner-wrapper">
                <div className="footer-inner width-33">
                    <div className="footer-padding">
                        <div className="footer-header">
                            <b>HVOR</b> ER VI
                        </div>
                        <div className="footer-text">
                            Bergen Vest
                            <br />Os og omegn
                        </div>
                    </div>
                </div>
                <div className="footer-inner width-33">
                    <div className="footer-padding">
                        <div className="footer-header">
                            <b>SOSIALE</b> MEDIER
                        </div>
                        <div className="footer-icons">
                            <div>Facebook</div>
                            <div>Instagram</div>
                        </div>
                    </div>
                </div>
                <div className="footer-inner width-33">
                    <div className="footer-padding">
                        <div className="footer-header">
                            <b>ANNET</b>
                        </div>
                        <div className="footer-icons">
                            <NavLink className="footer-link" onClick={scrollToTop} to="/personvern">Personvernerklæring</NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer