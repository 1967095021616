import { NavLink } from "react-router-dom"
import { HashLink } from "react-router-hash-link"
import { useEffect, useState } from "react"

const Header = () => {

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true })
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])
    
    const [isScrolled, setScrolled] = useState(false)

    const handleScroll = () => {
        if(window.scrollY > 160) {
            setScrolled(true)
        } else {
            setScrolled(false)
        }
    }
    
    function scrollToTop() {
        window.scrollTo(0, 0)
    }

    return (
        <div className={`header ${isScrolled && 'header-scrolled'}`}>
            <NavLink onClick={scrollToTop} className="header-left" to="/">
                <img className="header-logo" src="./logo6.png" alt="Logo" />
            </NavLink>
            <div className="header-right">
                <div className="nav-link" >
                    <NavLink onClick={scrollToTop} to="/">HJEM</NavLink>
                </div>
                <div className="nav-link" >
                    <NavLink onClick={scrollToTop} to="/tjenester">TJENESTER</NavLink>
                </div>
                <div className="nav-link" >
                    <HashLink to="/#kontakt">KONTAKT</HashLink>
                </div>
                <div className="nav-link" >
                    <NavLink onClick={scrollToTop} to="/om-oss">OM OSS</NavLink>
                </div>
            </div>
        </div>
    )
}

export default Header