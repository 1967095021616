const Reviews = () => {
    
    function calculateAge(birthday) {
        var ageDifMs = Date.now() - birthday
        var ageDate = new Date(ageDifMs)
        return Math.abs(ageDate.getUTCFullYear() - 1970)
    }
    
    return (
        <div className="section-review">
            <div className="review-center">
                <div className="review-title">Brukeranmeldelser</div>
                <div className="review-reviews">
                    <div className="review-box">
                        <div className="review-name">Her kommer det anmeldelser</div>
                        <div className="review-text">
                            Etter hvert som vi får dem. :)
                        </div>
                    </div>
                    <div className="review-box" style={{display: "none"}}>
                        <div className="review-name">Sigrunn, {calculateAge(new Date("1948-07-07"))}</div>
                        <div className="review-text">
                            Hadde behov for hjelp med TV-dekoder og fakturaer fra NextGenTel.
                            <br /><br />
                            Dette fikk jeg kjapt løst med hjelp fra DataVest! Anbefales!
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Reviews