const Personvern = () => {
    
    return (
        <div className="frontpage-wrapper">
            <div className="section">
                <div className="section-text">
                    <div className="paragraph">
                        Velkommen til Datavest sin personvernserklæring. Vi verdsetter ditt personvern og er forpliktet til å beskytte dine personopplysninger. Denne erklæringen forklarer hvordan vi samler inn, bruker og beskytter informasjon som vi mottar fra deg når du bruker vår nettside.
                    </div>

                    <h2>1. Hvilke opplysninger vi samler inn</h2>
                    <div className="paragraph">
                        Når du bruker vårt kontaktskjema, kan vi samle inn følgende opplysninger:
                        <ul>
                            <li>Navn</li>
                            <li>Telefonnummer</li>
                            <li>E-postadresse</li>
                            <li>Innholdet i din henvendelse</li>
                        </ul>
                    </div>

                    <h2>2. Hvordan vi bruker dine opplysninger</h2>
                    <div className="paragraph">
                        Informasjonen du gir via vårt kontaktskjema brukes til følgende formål:
                        <ul>
                            <li>Behandling og besvarelse av dine forespørsler</li>
                            <li>Administrasjon av kundeforholdet</li>
                            <li>Utstedelse av fakturaer gjennom Fiken.no dersom det er nødvendig</li>
                            <li>Håndtering av supportforespørsler via Spiceworks.com</li>
                        </ul>
                    </div>

                    <h2>3. Deling av dine opplysninger</h2>
                    <div className="paragraph">
                        Vi deler ikke dine personopplysninger med tredjeparter unntatt i følgende tilfeller:
                        <ul>
                            <li>Når det er nødvendig for å oppfylle en forespørsel eller avtale med deg, for eksempel gjennom Fiken for fakturering</li>
                            <li>Når vi bruker tredjeparts tjenester for å behandle henvendelser, som Spiceworks</li>
                        </ul>
                    </div>

                    <h2>4. Sikkerhet</h2>
                    <div className="paragraph">
                        Vi tar sikkerheten til dine personopplysninger på alvor. Vi bruker tekniske og organisatoriske tiltak for å beskytte informasjonen du gir oss. E-postkommunikasjon blir sikret gjennom bruk av krypteringsprotokoller.
                    </div>

                    <h2>5. Dine rettigheter</h2>
                    <div className="paragraph">
                        Du har rett til å:
                        <ul>
                            <li>Få innsyn i hvilke personopplysninger vi har om deg</li>
                            <li>Be om retting eller sletting av dine personopplysninger</li>
                            <li>Trekke tilbake ditt samtykke til behandling av personopplysninger</li>
                            <li>Få innsyn i hvilke personopplysninger vi har om deg</li>
                        </ul>
                        For å utøve dine rettigheter, vennligst kontakt oss via <a href="mailto:post@datavest.no">post@datavest.no</a>.
                    </div>

                    <h2>6. Endringer i denne personvernserklæringen</h2>
                    <div className="paragraph">
                        Vi kan oppdatere denne personvernserklæringen fra tid til annen. Eventuelle endringer vil bli publisert på denne siden med en oppdatert dato.
                    </div>

                    <h2>7. Kontaktinformasjon</h2>
                    <div className="paragraph">
                        Hvis du har spørsmål om denne personvernserklæringen eller vår behandling av personopplysninger, vennligst kontakt oss på <a href="mailto:post@datavest.no">post@datavest.no</a>.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Personvern