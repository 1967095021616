import ContactForm from '../modules/ContactForm'
import Reviews from '../modules/Reviews'

const Forside = () => {
    
    
    
    return (
        <div id="top" className="frontpage-wrapper">
            <div className="section stretch">
                <div className="section-image section1" />
                <div className="section-text">
                    <h2 className="first-title">Velkommen til Data Vest</h2>
                    <div className="paragraph paragraph-padding">
                        Din lokale ekspert på IT-støtte!
                    </div>
                    <div className="paragraph paragraph-padding">
                        Hos DataVest tilbyr vi profesjonell feilsøking og problemløsning for privatpersoner. Enten du trenger hjelp med din PC, nettbrett eller mobiltelefon, er vi her for å hjelpe deg. Vi tilbyr gratis evaluering av ditt IT-problem, slik at du kan kontakte oss uten kostnad. Hos oss betaler du ingenting før vi er ferdige med arbeidet. La oss gjøre teknologien enkel og tilgjengelig for deg!
                    </div>
                    <div className="paragraph paragraph-padding">
                        Vi tilbyr også rådgivning på en hel rekke med teknologi-relaterte problemstillinger. Er du på utkikk etter en ny mobiltelefon, men vet ikke hvilken som passer til deg? Kanskje du vurderer enten en laptop eller et nettbrett, men ønsker å vite hva fordelene eller ulempene med begge er? Dette kan vi hjelpe deg med!
                    </div>
                    <div className="paragraph paragraph-padding">
                        Med over 25 års delt erfaring innen IT og support kan du stole på at du får både gode råd og utmerket hjelp!
                    </div>
                    <div className="paragraph paragraph-padding">
                        Kontakt oss i dag for en gratis vurdering!
                    </div>
                </div>
                <div className="section-image-mobile section1" />
                <img className="section-separator-1" src="./frontpage/separator1.svg" alt="separator" />
            </div>
            
            <Reviews />
            
            <ContactForm />
        </div>
    )
}

export default Forside