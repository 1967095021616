import {useState,useCallback} from 'react'
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3'

const ContactForm = () => {
    
    const [verifyToken, setVerifyToken] = useState()
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false)
    
    const refreshToken = () => {
        setRefreshReCaptcha(r => !r)
    }
    
    const [response, setResponse] = useState()
    const [success, setSuccess] = useState(false)
    
    async function handleSubmit(event) {
        event.preventDefault()
        refreshToken()
        setSuccess(false)
        let formData = {
            name: event.target.name.value,
            phone: event.target.phone.value,
            email: event.target.email.value,
            subject: event.target.subject.value,
            verifyToken: verifyToken
        }
        fetch('/sendContactForm', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(formData)
        })
        .then(response => {return response.json()})
        .then(data => {
            setResponse(data)
        })
    }
    function errorHandler(status) {
        if (status && status.success === false) {
            if (status.errors) {
                return <div className="contact-failure">Vennligst fyll manglende felter!</div>
            } else {
                return <div className="contact-failure">Feil med reCAPTCHA, refresh siden!</div>
            }
        } else if (status && status.success === true) {
            document.getElementById("contact-form").reset()
            setResponse(undefined)
            setSuccess(true)
        }
    }
    
    const verifyRecaptchaCallback = useCallback((token) => {
        setVerifyToken(token)
    }, [])
    
    return (
        <div id="kontakt" className="section stretch">
            <img className="section-separator-2" src="./frontpage/separator2.svg" alt="separator" />
            <div className="section-text">
                <h2 className="first-title">Kontaktskjema</h2>
                <form id="contact-form" className="contact-form" autoComplete="off" onSubmit={e => handleSubmit(e)}>
                    <label htmlFor="name">Navn</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Navn..."
                        data-lpignore="true"
                    />

                    <label htmlFor="phone">Telefon</label>
                    <input
                        type="text"
                        id="phone"
                        name="phone"
                        placeholder="Telefon..."
                        data-lpignore="true"
                    />

                    <label htmlFor="email">Epost</label>
                    <input
                        type="text"
                        id="email"
                        name="email"
                        placeholder="Epost..."
                        data-lpignore="true"
                    />

                    <label htmlFor="subject">Henvendelse</label>
                    <textarea
                        id="subject"
                        name="subject"
                        placeholder="Henvendelse..."
                        data-lpignore="true"
                    />
                    <GoogleReCaptchaProvider reCaptchaKey="6Le-nB0qAAAAAJi91jvizcDPVORMIHY4twb4bQ8t">
                        <GoogleReCaptcha
                            onVerify={verifyRecaptchaCallback}
                            refreshReCaptcha={refreshReCaptcha}
                        />
                    </GoogleReCaptchaProvider>

                    <input type="submit" value="Send" />
                    {errorHandler(response)}
                    {success === true ? <div className="contact-success">Takk for din henvendelse!</div> : undefined}
                </form>
                <div className="grecaptcha-disclaimer">This site is protected by reCAPTCHA and the Google
                    <a href="https://policies.google.com/privacy"> Privacy Policy</a> and
                    <a href="https://policies.google.com/terms"> Terms of Service</a> apply.
                </div>
            </div>
            <div className="section-image section2" />
        </div>
    )
}

export default ContactForm