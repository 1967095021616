

const Tjenester = () => {
    
    
    
    return (
        <div className="frontpage-wrapper">
            <div className="section stretch">
                <div className="section-image section1" />
                <div className="section-text">
                    <h2 className="first-title">Hvilke tjenester tilbyr vi?</h2>
                    
                    <h3>IT og teknologihjelp</h3>
                    <div className="paragraph">
                        DataVest er dedikert til å løse dine tekniske utfordringer. Vi tilbyr hjelp med en rekke enheter, inkludert PC, mobiltelefon, nettbrett, TV-dekoder, Apple TV og mer. Med fokus på effektivitet og kundetilfredshet sørger vi for at du får den hjelpen du trenger uten å måtte bekymre deg for kostnader før arbeidet er ferdig.
                    </div>
                    
                    <h3>Rådgivning</h3>
                    <div className="paragraph">
                        Hos DataVest tilbyr vi også profesjonell rådgivning for alle dine IT-behov. Er du usikker på om du bør oppgradere din PC, eller vurdere å kjøpe en ny enhet? Vi hjelper deg med å ta informerte beslutninger. Vårt erfarne team kan gi deg råd om:
                        <br />
                        <ul className="checkmark-ul">
                            <li>Oppgradering av eksisterende utstyr.</li>
                            <li>Valg av ny PC, mobiltelefon, nettbrett, TV-dekoder, Apple TV og andre enheter.</li>
                            <li>Optimalisering av teknologi for dine behov.</li>
                        </ul>
                        Vi er her for å sørge for at du får mest mulig ut av din teknologi, uten unødvendige kostnader eller stress. Kontakt oss i dag for gratis rådgivning og la oss hjelpe deg med å navigere i den teknologiske jungelen!
                    </div>
                    
                    {/*<h3>Opplæring</h3>
                    <div className="paragraph">
                        Trenger du hjelp til å lære deg nye programmer eller funksjoner på PC-en? Hos DataVest tilbyr vi opplæring i forskjellige programvarer. Vårt mål er å gi deg en god start og veiledning, uansett alder og forkunnskaper. Selv om vi ikke erstatter dyre kurs, kan vi gi deg grunnleggende opplæring og støtte i:
                        <br />
                        <ul className="checkmark-ul">
                            <li>Bruk av operativsystemer som Windows og macOS.</li>
                            <li>Office-pakken (Word, Excel, PowerPoint).</li>
                            <li>E-postklienter og internettbruk.</li>
                            <li>Spesifikke programvarer etter behov.</li>
                        </ul>
                        La oss hjelpe deg med å bli tryggere og mer selvsikker i din bruk av teknologi. Kontakt oss for å avtale en opplæringssesjon!
                    </div>*/}
                    
                    <h3>Seniorhjelp</h3>
                    <div className="paragraph">
                        Hos DataVest forstår vi at teknologi kan være utfordrende, spesielt for våre eldre kunder. Derfor tilbyr vi spesialisert hjelp for seniorer. Vi kan bistå med en rekke tjenester, inkludert:
                        <br />
                        <ul className="checkmark-ul">
                            <li>Bruk av sosiale medier som Facebook, Instagram og Snapchat.</li>
                            <li>Håndtering av e-post og fakturaer.</li>
                            <li>Løsning av vanlige problemer med PC, nettbrett og mobiltelefoner.</li>
                            <li>Navigering på internett og bruk av nettbank.</li>
                            <li>Generell IT-støtte og veiledning.</li>
                        </ul>
                        Vårt tålmodige og vennlige team er her for å hjelpe deg med alle dine teknologiske behov, slik at du kan føle deg trygg og komfortabel med å bruke dine enheter. Kontakt oss i dag for å lære mer om hvordan vi kan hjelpe deg!
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tjenester