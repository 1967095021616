import { NavLink } from "react-router-dom"
import { HashLink } from "react-router-hash-link"

const OmOss = () => {
    
    
    
    return (
        <div className="frontpage-wrapper">
            <div className="section stretch">
                <div className="section-image">
                    <div className="about-us-centered">
                        <img className="about-us-image about-us-image1" src="./frontpage/andreas_k_olsen.jpg" alt="andreas" />
                        <img className="about-us-image about-us-image2" src="./frontpage/brede_tjonnteig.png" alt="andreas" />
                    </div>
                </div>
                <div className="section-text">
                    <h2 className="first-title">Hvem er vi?</h2>
                    <div className="paragraph">
                        <br />
                        Vi er to IT-entusiaster med mange års erfaring innen IT-arbeid, primært rettet mot bedrifter. Gjennom vårt daglige arbeid har vi lært at det er mange privatpersoner som trenger hjelp med IT-problemer hjemme, og ikke alltid har noen de kan kontakte for å få hjelp. Derfor startet vi DataVest – for å være din redningsvest med dataen din!
                        <br /><br />
                        Vi forstår hvor frustrerende teknologiske utfordringer kan være, og vårt mål er å gjøre teknologien enklere og mer tilgjengelig for alle. Med vår kompetanse og lidenskap for teknologi, er vi dedikert til å hjelpe deg med alle dine IT-behov, uansett hvor stort eller lite problemet måtte være.
                    </div>
                    
                    <div className="paragraph">&nbsp;</div>
                    
                    <h2>Ofte stilte spørsmål</h2>
                    
                    <h3>0. Tilbyr dere tjenester for bedrifter?</h3>
                    <div className="paragraph">
                        Dessverre ikke! Vi tilbyr kun tjenester rettet mot privatpersoner. Om du er en bedriftskunde og likevel bestemmer deg for å ta kontakt, så kan vi referere deg videre til en passende annen bedrift som kan hjelpe.
                    </div>
                    
                    <h3>1. Hva slags tjenester tilbyr DataVest?</h3>
                    <div className="paragraph">
                        DataVest tilbyr en rekke IT-tjenester, se en oversiktlig liste <NavLink className="" to="/tjenester">her</NavLink>.
                    </div>
                    
                    <h3>2. Tilbyr dere hardware-reparasjoner eller utskifting?</h3>
                    <div className="paragraph">
                        Nei, DataVest tilbyr ikke hardware-reparasjoner eller utskifting av komponenter. Våre tjenester fokuserer på programvare og generell IT-støtte. Vi kan likevel gi deg råd om hvor du kan få hjelp med dette, så ikke nøl med å ta kontakt!
                    </div>
                    
                    <h3>3. Hvordan kan jeg få en evaluering av IT-problemet mitt?</h3>
                    <div className="paragraph">
                        Vi tilbyr en gratis evaluering av ditt IT-problem. Du kan <HashLink to="/#kontakt">kontakte</HashLink> oss uten kostnad, og vi vil vurdere saken før vi starter arbeidet.
                    </div>
                    
                    <h3>4. Hvor mye koster deres tjenester?</h3>
                    <div className="paragraph">
                        Hos DataVest betaler du ingenting før vi er ferdige med arbeidet. Prisen vil avhenge av tjenesten som utføres, men du får alltid en prisvurdering før vi starter. Vi har en fastpris på 200kr Per påbegynte halvtime.
                    </div>
                    
                    <h3>5. Hvilke enheter kan dere hjelpe med?</h3>
                    <div className="paragraph">
                        Vi tilbyr støtte for datamaskiner, nettbrett og mobiltelefoner, inkludert både Windows, Mac, iOS og Android. I tillegg kan vi hjelpe med alt fra TV-dekodere til smart-kjøleskapet ditt.
                    </div>
                    
                    <h3>6. Hvordan kontakter jeg DataVest for hjelp?</h3>
                    <div className="paragraph">
                        Du kan kontakte oss via E-post eller vårt <HashLink to="/#kontakt">kontaktskjema</HashLink> på nettsiden. Vi er her for å hjelpe deg med dine IT-behov.
                        <br /><br />
                        Vi streber etter å tilby rask og effektiv service. Svartiden kan variere avhengig av etterspørselen, men vi gjør vårt beste for å hjelpe deg så fort som mulig.
                    </div>
                    
                    <h3>7. Hvor opererer vi?</h3>
                    <div className="paragraph">
                        Hos DataVest dekker vi primært områdene Bergen Vest og Bjørnafjorden (Os og omegn). Vi tilbyr en fleksibel tilnærming til IT-støtte, som inkluderer:
                        <ul className="checkmark-ul">
                            <li>Fjernstyring og e-post: Vi kan løse de fleste IT-problemer via fjernstyring og e-post, noe som betyr at vi kan hjelpe deg uansett hvor du befinner deg.</li>
                            <li>Utkjøring etter avtale: Ved behov er vi også villige til å kjøre ut til deg etter avtale for å tilby personlig hjelp.</li>
                        </ul>
                        Selv om du bor utenfor våre primære dekningsområder, kan vi fortsatt bistå deg. Merk at for kunder utenfor Bergen Vest og Bjørnafjorden vil alle fysiske oppmøter måtte avtales på forhånd, og ekstra kostnader kan forekomme pga reisetid og distanse.
                        <br /><br />
                        Kontakt oss i dag for å finne ut hvordan vi kan hjelpe deg best mulig, uansett hvor du befinner deg!
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OmOss